






























































































import { Vue, Component, Prop } from 'vue-property-decorator'
import {
  BAvatar,
  BButton,
  BCard, BCardBody,
  BCardHeader,
  BCardText,
  BCardTitle,
  BCol,
  BFormInput,
  BFormSelect, BFormSelectOption,
  BRow
} from "bootstrap-vue";
import {NotifyInfo} from "@/helpers/Toast";

@Component({
  components: {BCard, BCardHeader, BCardTitle, BCardText, BCardBody, BButton, BRow, BCol, BAvatar, BFormInput, BFormSelect, BFormSelectOption}
})
export default class ClientSettingEmailTemplatesCard extends Vue {
  @Prop({
    default: () => {
      return null;
    }, required: true
  })
  id: string

  @Prop({
    default: () => {
      return false;
    }, required: true
  })
  isEditing: boolean

  @Prop({
    default: () => {
      return []
    }, required: true
  })
  emailTemplates: [string: string][]

  startEditing(type: string) {
    if(this.editing.type != null)
      return NotifyInfo([`Please finish editing ${type} email template before continuing`]);

    this.$set(this, "editing", {
      type,
      value: this.emailTemplates[type] ?? "",
    });
  }

  stopEditing() {
    this.$set(this, "editing", {type: null, value: null});
    this.$emit("refetch");
  }

  async saveChanges() {
    await this.$api.maintenance.clientsettings.SetEmailTemplate(this.id, this.editing.type, this.editing.value);
    this.stopEditing();
  }

  startNew() {
    this.$set(this, "newItem", {type: null, value: null});
  }

  stopNew() {
    this.$set(this, "newItem", null);
  }

  async saveNew() {
    await this.$api.maintenance.clientsettings.SetEmailTemplate(this.id, this.newItem.type, this.newItem.value);
    this.stopNew();
    this.$emit("refetch");
  }


  editing: { type: string, value: string } = {type: null, value: null};
  newItem: { type: string, value: string } = null;
}
