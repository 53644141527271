


































































































import { Vue, Component, Prop } from 'vue-property-decorator'
import {
  BAvatar,
  BButton,
  BCard, BCardBody,
  BCardHeader,
  BCardText,
  BCardTitle,
  BCol,
  BFormInput,
  BFormSelect, BFormSelectOption,
  BRow
} from "bootstrap-vue";
import {NotifyInfo} from "@/helpers/Toast";

@Component({
  components: {BCard, BCardHeader, BCardTitle, BCardText, BCardBody, BButton, BRow, BCol, BAvatar, BFormInput, BFormSelect, BFormSelectOption}
})
export default class ClientSettingUrlsCard extends Vue {
  @Prop({
    default: () => {
      return null;
    }, required: true
  })
  id: string

  @Prop({
    default: () => {
      return false;
    }, required: true
  })
  isEditing: boolean

  @Prop({
    default: () => {
      return []
    }, required: true
  })
  urls: [string: string][]

  @Prop({
    default: () => {
      return []
    }, required: true
  })
  urlTypes: {value:string, text:string}[]

  getUrlTypeText(value: string) {
    return this.urlTypes.find(type => type.value === value).text
  }

  get unusedUrlTypes() {
    return this.urlTypes.filter(x=>!Object.keys(this.urls).includes(x.value))
  }

  startEditing(type: string) {
    if(this.editing.type != null)
      return NotifyInfo([`Please finish editing ${this.getUrlTypeText(type)} url before continuing`]);

    this.$set(this, "editing", {
      type,
      value: this.urls[type] ?? "",
    });
  }

  stopEditing() {
    this.$set(this, "editing", {type: null, value: null});
    this.$emit("refetch");
  }

  async saveChanges() {
    await this.$api.maintenance.clientsettings.SetUrl(this.id, this.editing.type, this.editing.value);
    this.stopEditing();
  }

  startNew() {
    this.$set(this, "newItem", {type: null, value: null});
  }

  stopNew() {
    this.$set(this, "newItem", null);
  }

  async saveNew() {
    await this.$api.maintenance.clientsettings.SetUrl(this.id, this.newItem.type, this.newItem.value);
    this.stopNew();
    this.$emit("refetch");
  }



  editing: { type: string, value: string } = {type: null, value: null};
  newItem: { type: string, value: string } = null;
}
