




























































































import {Vue, Component, Prop} from 'vue-property-decorator'
import {BAvatar, BButton, BCard, BCol, BFormInput, BRow} from "bootstrap-vue";
import User = EmployeeAPI.User.User;
import {avatarText} from '@/helpers/filters/avatarText';

@Component({
  components: {BCard, BButton, BRow, BCol, BAvatar, BFormInput}
})
export default class ClientSettingInfoCard extends Vue {
  @Prop({
    default: () => {
      return {}
    }, required: true
  })
  item?: MaintenanceAPI.ClientSettings.Item;

  @Prop({
    default: () => {
      return false;
    }, required: true
  })
  isEditing: boolean

  avatarText() {
    return avatarText(this.item?.name)
  }

  async clone() {
    const clone = await this.$api.maintenance.clientsettings.Clone(this.item.id);
    await this.$router.push({
      name: "maintenance-clientsetting",
      params: {
        identifier: clone.id
      }
    })
  }

  async save() {
    await this.$api.maintenance.clientsettings.Update(this.item.id, this.item.name);
    this.$emit('toggleEditing');
  }
}
