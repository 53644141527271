























import {Vue, Component, Prop, Watch} from 'vue-property-decorator'
import {
  BAvatar,
  BBadge,
  BDropdown,
  BDropdownItem,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BPagination,
  BButton, BCol, BRow, BCard, BInputGroup, BCardTitle
} from "bootstrap-vue";

import InfoCard from "./components/info-card.vue";
import UrlsCard from './components/urls-card.vue';
import EmailTemplatesCard
  from "@/apps/maintenance-api/pages/ClientSettings/single/components/email-templates-card.vue";
import SmsTemplatesCard from './components/sms-templates-card.vue';

@Component({
  components: {
    BAvatar,
    BBadge,
    BPagination,
    BInputGroup,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BButton,
    BRow,
    BCol,
    BCard,
    BCardTitle,
    InfoCard,
    UrlsCard,
    EmailTemplatesCard,
    SmsTemplatesCard
  }
})
export default class ClientSetting extends Vue {
  item: MaintenanceAPI.ClientSettings.Item = null;

  @Watch("id", {immediate: true})
  async watchId() {
    if(this.$route.params.edit != undefined)
      this.editing = true;

    await this.getItem();
  }


  get id() {
    return this.$route.params.identifier;
  }

  async getItem() {
    const item = await this.$api.maintenance.clientsettings.Get(this.id);
    this.$set(this, "item", item);
  }

  async toggleEditing() {
    this.editing = !this.editing;
    await this.getItem();
  }

  editing = false;
}
